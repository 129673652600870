var path = process.env.PUBLIC_URL;

const LowResImages = [    
    { src: path + "/imageslowres/1.jpg", width: 400, height: 225 },
    { src: path + "/imageslowres/2.jpg", width: 400, height: 225 },
    { src: path + "/imageslowres/3.jpg", width: 400, height: 225 },
    { src: path + "/imageslowres/4.jpg", width: 400, height: 225 },
    { src: path + "/imageslowres/5.jpg", width: 400, height: 225 },
    { src: path + "/imageslowres/6.jpg", width: 400, height: 225 },
    { src: path + "/imageslowres/7.jpg", width: 400, height: 225 },
    { src: path + "/imageslowres/8.jpg", width: 400, height: 225 },
    { src: path + "/imageslowres/9.jpg", width: 400, height: 225 },
    { src: path + "/imageslowres/10.jpg", width: 400, height: 225 },
    { src: path + "/imageslowres/11.jpg", width: 400, height: 225 },
    { src: path + "/imageslowres/12.jpg", width: 400, height: 225 },
    { src: path + "/imageslowres/13.jpg", width: 400, height: 225 }
];

export default LowResImages;