var path = process.env.PUBLIC_URL;

const Images = [    
    { src: path + "/images/1.jpg", width: 1600, height: 900 },
    { src: path + "/images/2.jpg", width: 1600, height: 900 },
    { src: path + "/images/3.jpg", width: 1600, height: 900 },
    { src: path + "/images/4.jpg", width: 1600, height: 900 },
    { src: path + "/images/5.jpg", width: 1600, height: 900 },
    { src: path + "/images/6.jpg", width: 1600, height: 900 },
    { src: path + "/images/7.jpg", width: 1600, height: 900 },
    { src: path + "/images/8.jpg", width: 1600, height: 900 },
    { src: path + "/images/9.jpg", width: 1600, height: 900 },
    { src: path + "/images/10.jpg", width: 1600, height: 900 },
    { src: path + "/images/11.jpg", width: 1600, height: 900 },
    { src: path + "/images/12.jpg", width: 1600, height: 900 },
    { src: path + "/images/13.jpg", width: 1600, height: 900 }
];

export default Images;