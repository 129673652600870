import "./App.css";
import Gallery from "./Album/Album";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCrow } from '@fortawesome/free-solid-svg-icons'


function App() {
  return (
    <div className="App">
      <div className="App-header">
        <h1><FontAwesomeIcon icon={faCrow} /> The Birbers </h1>
      </div>
      <div className="Gallery">
        <Gallery />
      </div>
    </div>
  )
}

 

export default App;
