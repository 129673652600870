import PhotoAlbum from "react-photo-album";
import "yet-another-react-lightbox/styles.css";
import { useState } from "react";

import Images from "../Data/Images";
import LowResImages from "../Data/ImagesLowRes";
import Lightbox from "yet-another-react-lightbox";


const Gallery = () => {
    const [index, setIndex] = useState(-1);


    return (
      <>
        <Lightbox 
          open={index >= 0}
          index={index}
          close={() => setIndex(-1)}
          slides={Images}
          />
        <PhotoAlbum 
          layout="rows" 
          photos={LowResImages} 
          onClick={({ index }) => setIndex(index)
        }/>

      </>
    );
  }



export default Gallery;